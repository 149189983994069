import {
  FETCH_MECHANISM,
  GrapplerLogger,
  SENDBEACON_MECHANISM,
} from 'saddlebag-grappler';
import { logError, logOperationalEvent } from 'saddlebag-logger';
import observer from 'saddlebag-observer';
import { isNonEssentialTrackingEnabled } from 'saddlebag-tracking-preferences';
import { MiniEventsTracking } from 'saddlebag-user-tracking-events';

let grapplerTracker;
let miniEventTracker;
let miniEventTrackerAsync;
let initializedListeners = false;

const getMiniEventTracker = (environment, componentName, async) => {
  if (miniEventTracker && async) return miniEventTrackerAsync;
  if (miniEventTracker) return miniEventTracker;

  const env = environment || 'public';
  // instansiate both on first call
  miniEventTracker = new MiniEventsTracking(env, componentName, {
    enhance: false,
    mechanism: FETCH_MECHANISM,
  });
  miniEventTrackerAsync = new MiniEventsTracking(env, componentName, {
    enhance: false,
    mechanism: SENDBEACON_MECHANISM,
  });
  if (async) {
    return miniEventTrackerAsync;
  }
  return miniEventTracker;
};

const getGrapplerTracker = (environment, componentName, async) => {
  if (grapplerTracker) return grapplerTracker;

  const env = environment || 'public';
  grapplerTracker = new GrapplerLogger(env, componentName, {
    enhance: false,
    mechanism: async ? SENDBEACON_MECHANISM : FETCH_MECHANISM,
  });
  return grapplerTracker;
};

const sendGrapplerEvent = async (
  environment,
  componentName,
  data,
  async = false,
) => {
  try {
    if (data.isMiniEvent === true) {
      return getMiniEventTracker(environment, componentName, async).track(
        data.eventName,
        data.fullSchemaName,
        data.message,
        data.messageOpts,
      );
    }
    return getGrapplerTracker(environment, componentName, async).sendEvent(
      data.eventName,
      data.fullSchemaName,
      data.message,
    );
  } catch (err) {
    logError(err, {
      libraryName: 'trackolding',
      component: componentName,
      eventType: data?.eventName,
    });
    return {};
  }
};

/**
 * @param {string} environment environment, public or public-sandbox
 * @param {string} componentName component name
 * @param {string} market Market of operation
 * @param {object} data Object containing the event to be sent to grappler (message),
 * type of the event (isMiniEvent) and if consent is required (requiredConsent)
 * @returns {*} Returns nothing
 */
const trackGrapplerEvent = async (
  environment,
  componentName,
  market,
  data,
  async = false,
) => {
  if (data && data.eventName) {
    if (data.consentRequired !== false) {
      if (!isNonEssentialTrackingEnabled(market)) {
        return {};
      }
    }
    return sendGrapplerEvent(environment, componentName, data, async);
  }
  logOperationalEvent({
    eventName: 'GrapplerTrack_missingEventName',
    message: `Failed to track event as eventName not provided, market: ${JSON.stringify(
      market,
    )}, data: ${JSON.stringify(data)}`,
    libraryName: 'trackolding',
    component: componentName,
  });
  return {};
};

/**
 * @returns {*} Returns nothing
 * @param  {string} environment environment, public or public-sandbox
 * @param  {string} componentName component name
 * @param {string} market Market of operation
 */
const initGrapplerListeners = (environment, componentName, market) => {
  if (initializedListeners) return;
  initializedListeners = true;
  observer.subscribe('grappler-track', (data) => {
    if (componentName && data && market && data.isMiniEvent != null) {
      return trackGrapplerEvent(
        environment,
        componentName,
        market,
        data,
        false,
      );
    }
    logOperationalEvent({
      eventName: 'grapplerTrack_invalidParameters',
      message: 'Failed to track grappler events. Invalid input parameters.',
      libraryName: 'trackolding',
      component: componentName,
    });
    return {};
  });
  observer.subscribe('grappler-track-async', (data) => {
    if (componentName && data && market && data.isMiniEvent != null) {
      return trackGrapplerEvent(environment, componentName, market, data, true);
    }
    logOperationalEvent({
      eventName: 'grapplerTrack_invalidParameters',
      message: 'Failed to track grappler events. Invalid input parameters.',
      libraryName: 'trackolding',
      component: componentName,
    });
    return {};
  });
};

export { initGrapplerListeners, sendGrapplerEvent, trackGrapplerEvent };
