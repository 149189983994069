import logger from 'saddlebag-logger';

import { race } from './race';

const USER_INFO_TIMEOUT = 500;
let wait;

const timeout = (timeoutInMillis, data) =>
  new Promise((resolve) => {
    wait = setTimeout(() => {
      logger.logOperationalEvent({
        eventName: 'getUserInfo_Timeout',
        message: `Call to $window.__trackolding.userInfoCallback() timed out after ${timeoutInMillis}`,
        libraryName: 'trackolding',
      });
      resolve(data.userInfo);
    }, timeoutInMillis);
  });

export function getUserInfo(data, $window) {
  const isUserInfoMissing = !((data || {}).userInfo || {}).utid;
  const isUserInfoCallbackDefined =
    typeof $window.__trackolding.userInfoCallback === 'function';
  if (isUserInfoCallbackDefined) {
    return race([
      timeout(USER_INFO_TIMEOUT, data),
      $window.__trackolding.userInfoCallback(),
    ])
      .then((response) => {
        clearTimeout(wait);
        return response;
      })
      .catch((error) => {
        clearTimeout(wait);
        logger.logError(error, {
          libraryName: 'trackolding',
        });
        return data.userInfo;
      });
  }
  if (!isUserInfoCallbackDefined && isUserInfoMissing) {
    logger.logOperationalEvent({
      eventName: 'getUserInfo_MisssingData',
      message:
        'data.userInfo or data.userInfo.utid not available and $window.__trackolding.userInfoCallback has not been set',
      libraryName: 'trackolding',
    });
  }
  return Promise.resolve(data.userInfo);
}
