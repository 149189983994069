import { GLOBAL, getUserAgent } from 'saddlebag-browser';
import { SingleValueCookie } from 'saddlebag-cookie';
import { SENDBEACON_MECHANISM } from 'saddlebag-grappler';
import { logError } from 'saddlebag-logger';
import {
  getPreferences,
  PREFERENCES,
  isGdprInitialised,
  isGdprInitSessionActive,
} from 'saddlebag-tracking-preferences';
import { FunnelEventsTracking } from 'saddlebag-user-tracking-events';

import { getPlatform, getSkyscannerModifiers } from './experimentUserContext';
import { redactUrl } from './redactUrl';

let funnelTracker;

const getFunnelTracker = (data, mechanism) => {
  if (funnelTracker) return funnelTracker;

  const env = (data.config && data.config.env) || 'public';
  funnelTracker = new FunnelEventsTracking(env, data.microsite.name, {
    enhance: false,
    mechanism,
  });
  return funnelTracker;
};

const sendEvent = async (data, eventName, event, opts = {}) => {
  const tracker = getFunnelTracker(data);

  try {
    await tracker.track(eventName, event, opts);
  } catch (err) {
    logError(err, {
      component: 'funnelEvents',
    });
  }
};

const sendFunnelViewEvent = async (data) => {
  const $window = GLOBAL.getWindow();
  const { allowedQueryParams } = data.pageContext || {};
  const currentUrl =
    ($window.location &&
      redactUrl($window.location.href.replace(/#.*/, ''), {
        allowedQueryParams,
      })) ||
    'UNKNOWN';
  const referrerUrl = GLOBAL.getDocument().referrer || 'UNKNOWN';
  const opts = data.viewGuid ? { messageGuid: data.viewGuid } : {};

  const viewData = {
    name: currentUrl,
    referrer_url: referrerUrl,
  };

  await sendEvent(data, 'clients.View', viewData, opts);
};

const isInternalReferral = () => {
  try {
    const referrerUrl = new URL(GLOBAL.getDocument().referrer);
    const referredUrl = new URL(GLOBAL.getDocument().documentURI);

    if (!referrerUrl || !referredUrl) {
      return false;
    }

    const referredHostname = referredUrl.hostname.toLowerCase();
    const referrerHostname = referrerUrl.hostname.toLowerCase();

    return referredHostname === referrerHostname;
  } catch (err) {
    return false;
  }
};

const getDeviceCategory = (device) => {
  switch (true) {
    case device.isBrowser:
      return 'DESKTOP';
    case device.isMobilePhone:
      return 'MOBILE';
    case device.isTablet:
      return 'TABLET';
    default:
      return 'UNSET_DEVICE_CATEGORY';
  }
};

const sendFunnelAcquisitionEvent = async (data) => {
  if (isInternalReferral()) {
    return;
  }
  const device = data.device || {};

  const acquisitionEvent = {
    platform_type: 'WEB',
    acquisition_type: 'WEB_SESSION',
    referral_url: GLOBAL.getDocument().referrer,
    user_agent_string: getUserAgent(),
    device_category: getDeviceCategory(device),
    os_name: device.osName || '',
    os_version: device.osVersion || '',
    is_browser: device.isBrowser === true,
    browser_name: device.browserName || '',
    browser_version: device.browserVersion || '',
    model_name: device.model || '',
    vendor_name: device.vendor || '',
    marketing_name: device.marketingName || '',
    is_robot: device.isRobot === true,
    primary_hardware_type: device.primaryHardwareType || '',
    is_new_utid: data.isNewUser === true,
  };

  await sendEvent(data, 'clients.Acquisition', acquisitionEvent);
};

const getExperiments = (experimentString = '') => {
  if (experimentString.indexOf('&&') === -1) {
    return [];
  }
  return experimentString.split('&&').map((pair) => {
    const [experiment, variant] = pair.split('-');
    const [name, version] = experiment.split(/_V(\d+)$/);
    return {
      name,
      version: parseInt(version, 10),
      variant,
    };
  });
};

const sendExperimentationAllocationEvent = async (data) => {
  const ssab = new SingleValueCookie('ssab').getValue();

  // Only emit events if emitExperimentAllocationEventInTrackolding is true and ssab cookie is present
  if (
    data.config &&
    data.config.emitExperimentAllocationEventInTrackolding &&
    ssab
  ) {
    const schema = 'clients.ExperimentAllocation';
    const tracker = getFunnelTracker(data);

    const experimentAllocationId = new SingleValueCookie(
      'experiment_allocation_id',
    ).getValue();

    if (tracker.eventHasBeenLogged(schema, { experimentAllocationId })) return;

    const experimentList = getExperiments(data.featureTestsString);

    await sendEvent(data, 'clients.ExperimentAllocation', {
      experiments: experimentList,
    });
    tracker.markEventAsLogged(schema, { experimentAllocationId });
  }
};

const sendExperimentUserContextEvent = async (data) => {
  const jha = new SingleValueCookie('jha').getValue();

  // Only emit events if emitExperimentAllocationEventInTrackolding is true and JHA cookie is present
  if (
    data.config &&
    data.config.emitExperimentAllocationEventInTrackolding &&
    jha
  ) {
    const schema = 'experiment_user_context.ExperimentUserContext';
    const tracker = getFunnelTracker(data);

    const experimentAllocationId = new SingleValueCookie(
      'experiment_allocation_id',
    ).getValue();

    // Only send event when a user's experiment allocations has been updated
    if (tracker.eventHasBeenLogged(schema, { experimentAllocationId })) return;

    const ssabOverrides = new SingleValueCookie('ssaboverrides').getValue();

    const culture = data.culture || {};
    const experimentUserContextEvent = {
      market: culture.market || '',
      locale: culture.locale || '',
      platform: getPlatform(data),
      currency: culture.currency || '',
      skyscanner_modifiers: getSkyscannerModifiers(data),
      flight_provider_id: data.flightProviderId || '',
      utm_medium: data.utmMedium || '',
      web_context: {
        page_type: data.pageType || '',
      },
      ssab_overrides: ssabOverrides || '',
    };

    await sendEvent(data, schema, experimentUserContextEvent);
    tracker.markEventAsLogged(schema, { experimentAllocationId });
  }
};

const sendUserPreferencesEvent = async (
  data,
  mechanism = SENDBEACON_MECHANISM,
) => {
  const schema = 'clients.UserPreferences';

  const trackingPreferences = getPreferences(data.culture.market);
  const isGdprInit = isGdprInitialised() || isGdprInitSessionActive();
  const userPreferences = {
    country: data.culture.market,
    currency: data.culture.currency,
    locale: data.culture.locale,
    is_optedin_for_personalised: trackingPreferences[PREFERENCES.ADVERTS],
    is_optedin_for_optimization: trackingPreferences[PREFERENCES.INFORMATION],
    is_gdpr_active: trackingPreferences.isGdprActiveInMarket,
    gdpr_cookie_version: trackingPreferences[PREFERENCES.VERSION],
    is_gdpr_initialised: isGdprInit,
  };

  const tracker = getFunnelTracker(data, mechanism);
  if (tracker.eventHasBeenLogged(schema, userPreferences)) return;

  await sendEvent(data, schema, userPreferences);
  tracker.markEventAsLogged(schema, userPreferences);
};

export {
  sendFunnelViewEvent,
  sendFunnelAcquisitionEvent,
  sendExperimentationAllocationEvent,
  sendExperimentUserContextEvent,
  sendUserPreferencesEvent,
};
